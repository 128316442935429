import React, { useState } from 'react';
import { Box, Container, Modal, Button, Typography } from '@mui/material';
import { Checkout } from '../Checkout';
import SubscriptionContainer from '../Subscription';
import Image from 'next/image';
import ChevronLeftIconComponent from '../../../public/icons/components/chevron-left';
import { MuiIconButton } from '@/components';
import { palette } from '@/theme/constants';

interface SubscriptionPopupProps {
  open: boolean;
  onClose: () => void;
}

export const SubscriptionPopup: React.FC<SubscriptionPopupProps> = ({ open, onClose }) => {
  const [step, setStep] = useState(1);

  const goToNextStep = () => setStep(2);

  const handleClose = () => {
    setStep(1);
    onClose();
  };

  return (
    <Modal open={open} onClose={handleClose}>
      <Container
        maxWidth='lg'
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100%',
          overflow: 'hidden',
        }}>
        <Box
          sx={{
            backgroundColor: '#131544',
            padding: 4,
            borderRadius: 2,
            boxShadow: 24,
            width: '100%',
            overflow: 'hidden',
            maxWidth: 800,
          }}>
          {step === 1 && (
            <>
              <Typography variant='h6' component='h2' gutterBottom>
                Complete Your Subscription
              </Typography>
              <SubscriptionContainer handleNext={goToNextStep} />
              <Box mt={2} display='flex' justifyContent='space-between'>
                <Button onClick={handleClose} color='error' variant='outlined'>
                  <Typography variant='button'> Cancel </Typography>
                </Button>
                <Button onClick={goToNextStep} variant='contained'>
                  <Typography variant='button'> Proceed to Payment </Typography>
                </Button>
              </Box>
            </>
          )}
          {step === 2 && (
            <>
              <Box mt={2} display='flex' justifyContent='flex-start'>
                <Button
                  onClick={() => {
                    setStep(1);
                  }}
                  color='error'>
                  <MuiIconButton
                    icon='/icons/left-arrow'
                    background={palette?.cardBackground}
                    borderColor={palette?.cardBorder}
                    iconHeight={16}
                    iconWidth={16}
                    width={32}
                    height={32}
                    altIcon='left-arrow'
                  />
                </Button>
              </Box>
              <Checkout handleClose={handleClose} />
            </>
          )}
        </Box>
      </Container>
    </Modal>
  );
};
