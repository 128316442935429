import { actionObject } from '../../utils';
import { 
  UPDATE_PRODUCT, 
  CREATE_PRODUCT, 
  GET_PRODUCTS, 
  GET_PRODUCT, 
  CLEAR_DATA_PRODUCTS, 
  SET_SELECTED_TIER,
  CLEAR_SELECTED_TIER
} from './action-types';

export const updateProduct = (body: any): any =>
  actionObject(UPDATE_PRODUCT, body);

export const createProduct = (body: any): any =>
  actionObject(CREATE_PRODUCT, body);


export const getProducts = (payload: any): any =>
  actionObject(GET_PRODUCTS, payload);

export const getProduct = (payload: any): any =>
  actionObject(GET_PRODUCT, payload);

export const clearDataProducts = (): any =>
  actionObject(CLEAR_DATA_PRODUCTS);

export const setSelectedTier = (payload: any) => actionObject(SET_SELECTED_TIER, payload);
export const clearSelectedTier = () => actionObject(CLEAR_SELECTED_TIER);
