// components/SubscriptionInfo.tsx
import React from 'react';
import { Typography, List, ListItem } from '@mui/material';

interface SubscriptionInfoProps {
  title: string;
  description: string;
}

const SubscriptionInfo: React.FC<SubscriptionInfoProps> = ({ title, description }) => {
  return (
    <>
      <Typography variant="h4" gutterBottom>
        Choose Your {title}
      </Typography>
      <Typography variant="body1" paragraph>
        {description}
      </Typography>
      <Typography variant="h6" gutterBottom>
        Why Choose Us?
      </Typography>
      <Typography variant="body2" paragraph>
      Lorem ipsum dolor sit amet, consectetur adipiscing elit
      </Typography>
      <List>
        <ListItem>Lorem Ipsum Item</ListItem>
        <ListItem>24/7 Dolor Sit Amet</ListItem>
        <ListItem>Consectetur Adipiscing</ListItem>
      </List>
    </>
  );
};

export default SubscriptionInfo;
