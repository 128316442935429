// components/SubscriptionPlanCard.tsx
import React from 'react';
import { Card, CardContent, Typography, List, ListItem } from '@mui/material';

interface Price {
  priceId: string;
  name: string;
  description: string;
  amount: number;
  currency: string;
  interval: string;
}

interface SubscriptionPlanCardProps {
  price: Price;
  selectedPlan: string | null;
  onSelect: (priceId: string) => void;
}

const SubscriptionPlanCard: React.FC<SubscriptionPlanCardProps> = ({ price, selectedPlan, onSelect }) => {
  return (
    <Card
      onClick={() => onSelect(price.priceId)}
      variant="outlined"
      sx={{
        marginBottom: '1rem',
        borderColor: selectedPlan === price.priceId ? 'primary.main' : 'grey.300',
        borderWidth: selectedPlan === price.priceId ? '2px' : '1px',
        cursor: 'pointer',
        backgroundColor: selectedPlan === price.priceId ? 'rgba(0, 0, 0, 0.44)' : 'inherit',
        '&:hover': {
          borderColor: 'primary.main',
          backgroundColor: 'rgba(0, 0, 0, 0.10)',
        },
      }}
    >
      <CardContent>
        <Typography variant="h6" gutterBottom>
          {price.name}
        </Typography>
        <Typography variant="body2" paragraph>
          {price.description}
        </Typography>
        <List>
          <ListItem sx={{ color: 'white' }}>
            Price: ${(price.amount / 100).toFixed(2)} {price.currency.toUpperCase()}
          </ListItem>
          <ListItem sx={{ color: 'white' }}>
            Billing Cycle: {price.interval}
          </ListItem>
        </List>
      </CardContent>
    </Card>
  );
};

export default SubscriptionPlanCard;
