import {
    UPDATE_PRODUCT,
    CREATE_PRODUCT,
    GET_PRODUCTS_ASYNC,
    GET_PRODUCT_ASYNC,
    CLEAR_DATA_PRODUCTS,
    UPDATE_PRODUCT_ASYNC,
    CREATE_PRODUCT_ASYNC,
    GET_PRODUCTS,
    GET_PRODUCT,
    SET_SELECTED_TIER_ASYNC,
    CLEAR_SELECTED_TIER,
  } from "./action-types";
  
  const initialState: any = {
    products: [],
    product: {},
    selectedTier: null,
    actionSuccess: null,
  };
  
  const subscription = (state = initialState, { type, payload }: any) => {
    switch (type) {
      case UPDATE_PRODUCT:
      case CREATE_PRODUCT: 
      case GET_PRODUCTS: 
      case GET_PRODUCT: 
        return {
          ...state,
          actionSuccess: null
        };
  

        
      case UPDATE_PRODUCT_ASYNC:
      case CREATE_PRODUCT_ASYNC:
        return {
          ...state,
          product: payload,
          actionSuccess: true
        };
  
      case GET_PRODUCT_ASYNC:
        return {
          ...state,
          product: payload
        };
  
      case GET_PRODUCTS_ASYNC:
        return {
          ...state,
          products: payload,
        };

        case SET_SELECTED_TIER_ASYNC:
          return {
            ...state,
            selectedTier: payload,
            actionSuccess: true,
          };
    
        case CLEAR_SELECTED_TIER:
          return initialState;
    
  
      case CLEAR_DATA_PRODUCTS:
        return initialState;


  
      default:
        return state;
    }
  };
  
  export default subscription;
  